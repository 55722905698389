<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary r-text">BRXMIND</h2>
        <h2 class="brand-text-pp">Braxxy Software e Projetos</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
            :title="$t('message.enterAccount.welcome')"
          >
          </b-card-title>
          <b-card-text class="mb-2">
            <template>
              {{ $t("message.enterAccount.makeYourLogin") }}
            </template>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email"
                >
                  <template v-sloat:label>
                    {{ $t("message.enterAccount.email") }}
                  </template>
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="joao@braxxy.com.br"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <template v-sloat:label>
                    {{ $t("message.enterAccount.password") }}
                  </template>

                  <b-link :to="{ name: 'forgot-password' }">
                    <template>
                      {{ $t("message.enterAccount.ForgotYourPassword") }}
                    </template>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <br />

              <!-- checkbox 
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  <template>
                    {{ $t("message.enterAccount.remember") }}
                  </template>
                </b-form-checkbox>
              </b-form-group>-->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
                v-if="!loadingSpinner"
              >
                <template>
                  {{ $t("message.enterAccount.enter") }}
                </template>
              </b-button>

              <b-button variant="primary" disabled type="submit" block v-else>
                <b-spinner small />
                Carregando...
              </b-button>
            </b-form>
            <div v-if="paramList != null">
              <b-link
                class="btn-register"
                v-if="paramList.organization"
                :to="{ name: 'register-page' }"
              >
                <template>
                  {{ $t("message.enterAccount.dontHasRegister") }}
                </template>
              </b-link>
            </div>
          </validation-observer>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              <template>
                {{ $t("message.enterAccount.or") }}
              </template>
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="google" href="mailto:contato@braxxy.com.br">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="https://www.braxxy.com.br/">
              <feather-icon icon="HomeIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <b-modal
      id="modal-otp"
      modal-class="modal-primary"
      centered
      :title="$t('secondCheck')"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      @show="isSelectedOptionOtp = false"
    >
    <template v-if="isSelectedOptionOtp">
      <b-col>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <template v-slot:label>
                {{ $t("secondSent1") + optionSent + $t("secondSent2") }}
              </template>
              <b-form-input id="value" v-model="otpCode" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <br />
      <div class="text-right">
        <b-button @click="checkOtpCode()" variant="primary">
          {{ $t("message.buttons.send") }}
        </b-button>
      </div>
    </template>
    <div v-else>
      <p>
        {{$t("optionSent")}}
      </p>
      <div class="d-flex align-items-center justify-content-center" style="gap: 1rem">
        <b-button
          v-for="otp in optionsOtp"
          :key="otp.name"
          variant="outline-primary"
          @click="sendOtp(otp.name)"
        >
          {{otp.label}}
        </b-button>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BModal,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import getHeader from "@core/utils/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import { avatarText } from "@core/utils/filter";
import VueCookies from "vue-cookies";
import Vue from "vue";
import sendToken from "@/@core/utils/email/emails/sendToken.js";

Vue.use(VueCookies);

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BModal,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    VueCookies,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      status: "",
      password: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      role: "admin",

      fullName: "",
      avatar: "",
      role: "",
      userData: null,
      employeeData: null,
      paramData: null,
      accessControl: null,

      loadingSpinner: false,

      paramList: null,

      userData: store.state.user.userData,

      otpCode: "",

      random: "",

      // validation rules
      required,
      email,

      objReturn: {},

      typeUser: "",
      isSelectedOptionOtp: false,
      optionsOtp: [],
      optionSent: ""
    };
  },

  created() {
    //axios.get(`${URL_API}user/root`).then(() => {});
    this.getParam();
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  methods: {
    getParam() {
      axios.get(`${URL_API}param-login`, {}).then((response) => {
        this.paramList = response.data;
      });
    },

    async checkOtpCode() {
      if (this.otpCode === this.random) {
        this.$cookies.set(
          this.email,
          "access-" + this.email,
          this.paramList.cookiesTime
        );

        //Limpa tentativas de usuário
        await axios.get(`${URL_API}user/attemps/${this.userData.email}`, {
          headers: getHeader(this.userData),
        });

        // Realiza login
        useJwt.setToken(this.objReturn.token);
        useJwt.setRefreshToken(this.objReturn.refreshToken);
        this.getUser(this.email, this.objReturn.token);
        store.commit("user/UPDATE_PASSWORD", this.password);
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Token inválido`,
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `Por favor tente fazer o login novamente`,
          },
        });
      }
    },

    authHeader() {
      // return authorization header with jwt token
      let user = store.state.user.userData;

      if (user && user.token) {
        return { Authorization: "Bearer " + user.token };
      } else {
        return {};
      }
    },

    async getAvatar(data) {
      await axios({
        method: "get",
        url: `${URL_API}file/${data.avatarFileNameBig}/${data.avatarPath}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.avatarImg = response.data;
        })
        .catch((error) => {
          this.avatarImg = "";
        });
    },

    async getEmployeeAPI() {
      if (this.userData.employeeId === "root") {
        this.employeeData = null;

        this.employeeData = {
          data: {
            fullName: "root",
            firstName: "root",
            type: "root",
            internalEmail: "root@braxxy.com.br",
            projectRole: "root",
            timeSheet: false,
          },
        };
      } else if (this.userData.employeeId === "organization-root") {
        this.employeeData = null;

        this.employeeData = {
          data: {
            fullName: this.email,
            projectRole: "organization-root",
            firstName: "organization-root",
            type: "organization-root",
            internalEmail: this.email,
            projectRole: "organization-root",
            timeSheet: false,
          },
        };
      } else {
        return await axios
          .get(`${URL_API}employee/` + this.userData.employeeId, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            this.employeeData = null;

            this.employeeData = {
              data: response.data,
            };
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    },

    async getParamAPI() {
      return await axios
        .get(`${URL_API}paramByTenant/`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.paramData = response.data;
        });
    },

    async getAccessControlAPI() {
      return await axios
        .get(`${URL_API}accessControl`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          if (response.data != "" && response.data != null) {
            this.accessControl = response.data;
          }
          this.checkAccessControl();
        });
    },

    async checkAccessControl() {
      if (this.accessControl === null) {
        var featureList = [];

        await axios
          .get(`${URL_API}feature`, { headers: getHeader(this.userData) })
          .then((response) => {
            if (response.data.length > 0) {
              featureList = response.data;
            }
          });

        var accessControlList = [];
        for (var i = 0; i < featureList.length; i++) {
          accessControlList.push({
            feature_id: featureList[i].feature_id,
            status: false,
          });
        }

        var newList = [];
        newList = [
          {
            accessControlAdministrative: accessControlList,
            accessControlFinancial: accessControlList,
            accessControlManager: accessControlList,
            accessControlUser: accessControlList,
            accessControlDirector: accessControlList,
            accessControlAdministrators: accessControlList,
            accessControlApplication: accessControlList,
            accessControlCustomer: accessControlList,
            accessControlCommercial: accessControlList,
            tenant: this.userData.tenant,
          },
        ];

        axios({
          method: "post",
          url: `${URL_API}accessControl`,
          headers: getHeader(this.userData),
          data: newList[0],
        }).catch((error) => {
          console.log(error);
          this.errored = true;
        });
      }
    },

    async generateOtpLogin(type) {
      const optionsOtp = []

      if(this.paramList.otpSms) {
        const obj = {
          name: "otpSms",
          label: "SMS",
          value: true
        }

        optionsOtp.push(obj);
      }

      if(this.paramList.otpEmail) {
        const obj = {
          name: "otpEmail",
          label: "Email",
          value: true
        }

        optionsOtp.push(obj);
      }

      this.optionsOtp = optionsOtp
      
      this.typeUser = type
      this.random = "";
      var shuffle = "0123456789";
      for (var i = 0; i < 6; i++) {
        this.random += shuffle.charAt(
          Math.floor(Math.random() * shuffle.length)
        );
      }
      
      this.loadingSpinner = false;
      this.$root.$emit("bv::show::modal", "modal-otp");
      if(this.optionsOtp.length === 1) {
        //chamando o envio com o unico meio de envio
        await this.sendOtp(this.optionsOtp[0].name)
      }
    },
    async sendOtp(type) {
      switch(type) {
        case "otpSms":
          await this.sendOtpSms()
        break;
        case "otpEmail":
          await this.sendOtpEmail()
        break;
      }
    },
    async sendOtpEmail() {
      this.isSelectedOptionOtp = true
      this.optionSent = "Email"

      const email = {
        ownerRef: "Braxxy",
        emailFrom: "brxmind@braxxy.com.br",
        emailTo:  this.userData.email,
        subject: "BRXMIND - [Código de acesso]",
        text: sendToken(this.random),
      }

      await axios
        .post(`${URL_API}otp/email`, email)
        .then((response) => {});
    },
    async sendOtpSms() {
      this.isSelectedOptionOtp = true
      this.optionSent = "SMS"

      var defaultMessage =
        " é seu código de acesso ao BRXMIND. Não o compartilhe.";

      var phoneNumber = "";
      this.loadingSpinner = false; // Parar loading
      this.$root.$emit("bv::show::modal", "modal-otp");

      if (this.typeUser === "master-root") {
        //se for master root
        phoneNumber = this.paramList.rootPhone;
      } else if (this.typeUser === "organization-root") {
        //se for organization root
        await axios
          .get(`${URL_API}organization-phone/` + this.userData.tenant, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            phoneNumber = response.data;
          });
      } else {
        // se for usuário comum
        await axios
          .get(`${URL_API}employee-phone/` + this.userData.employeeId, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            phoneNumber = response.data;
          });
      }

       const sms = {
        to: phoneNumber,
        message: this.random + defaultMessage
      }

      await axios
        .post(`${URL_API}otp/sms`, sms)
        .then((response) => {});
    },
    async login() {
      this.loadingSpinner = true;

      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.email,
              password: this.password,
            })
            .then((response) => {
              if (response.data != "" && response.data != null) {
                //Se for validado
                this.objReturn = response.data; // resposta para objReturn

                axios //get user
                  .get(`${URL_API}user/` + this.email, {
                    headers: this.authHeader(),
                  })
                  .then((response) => {
                    this.userData = null;
                    this.userData = {
                      id: response.data.id,
                      fullName: "",
                      avatar: null,
                      email: response.data.email,
                      employeeId: response.data.employeeId,
                      userGroup: response.data.userGroup,
                      password: "",
                      systemRole: response.data.systemRole,
                      bookmark: response.data.bookmark,
                      theme: response.data.theme,
                      locale: response.data.locale,
                      tour: response.data.tour,
                      tenant: response.data.tenant,
                      token: response.data.token,
                      sessionTime: response.data.sessionTime,
                      avatarFileNameSmall: response.data.avatarFileNameSmall,
                      avatarFileNameBig: response.data.avatarFileNameBig,
                      avatarPath: response.data.avatarPath,
                      reminder: response.data.reminder,
                      loginAttemps: response.data.loginAttemps,
                    };

                    //otp e tentativas
                    if (this.paramList.otpSms || this.paramList.otpEmail) {
                      //otp ativo
                      var loginAttemps = false;
                      if (this.userData.loginAttemps >= 3) {
                        //login com ou mais de 3 tentativas
                        loginAttemps = true;
                      } else {
                        loginAttemps = false;
                      }

                      if (
                        this.$cookies.get(this.email) === null ||
                        loginAttemps
                      ) {
                        //Se cache for igual a null ou 3 tentativas
                        if (this.email === "root@brxmind.com.br") {
                          this.generateOtpLogin("master-root");
                        } else if (
                          this.userData.employeeId === "organization-root"
                        ) {
                          this.generateOtpLogin("organization-root");
                        } else {
                          this.generateOtpLogin("normal");
                        }
                      } else {
                        useJwt.setToken(this.objReturn.token);
                        useJwt.setRefreshToken(this.objReturn.refreshToken);
                        this.getUser(this.email, this.objReturn.token);
                        store.commit("user/UPDATE_PASSWORD", this.password);
                      }
                    } else {
                      useJwt.setToken(this.objReturn.token);
                      useJwt.setRefreshToken(this.objReturn.refreshToken);
                      this.getUser(this.email, this.objReturn.token);
                      store.commit("user/UPDATE_PASSWORD", this.password);
                    }
                  })
                  .catch((error) => {
                    console.error("There was an error!", error);
                  });
              }
            })
            .catch((error) => {
              this.errorMessage();
              this.loadingSpinner = false;
              console.log(error);
              this.$refs.loginForm.setErrors(error.response.data.error);
            });
        }
      });
    },

    errorMessage() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Email ou/e senha inválidos`,
          icon: "AlertTriangleIcon",
          variant: "danger",
          text: `Por favor tente fazer o login novamente`,
        },
      });
    },

    async getUser(email, token) {
      await this.getEmployeeAPI();
      await this.getParamAPI();
      await this.getAccessControlAPI();

      //Setar avatar em userData de employeeData
      //if (this.employeeData.data.profilePicture == null) {
      //  this.userData.avatar = require("@/assets/images/avatars/avatar.jpg");
      //} else {
      //  this.userData.avatar = this.employeeData.data.profilePicture;
      //}

      //Check if avatar image exists or not
      if (
        this.userData.avatarFileNameBig != undefined &&
        this.userData.avatarPath != undefined
      ) {
        await this.getAvatar(this.userData);
        this.userData.avatar = this.avatarImg;

        if (this.userData.avatar === "") {
          this.userData.avatar = avatarText(
            this.employeeData.data.fullName.split(" ").slice(0, 2).join(" ")
          );
        }
      } else {
        this.userData.avatar = avatarText(
          this.employeeData.data.fullName.split(" ").slice(0, 2).join(" ")
        );
      }

      //employeeData global
      //localStorage.setItem("employeeData", JSON.stringify(this.employeeData));
      store.commit("user/UPDATE_EMPLOYEE_DATA", this.employeeData);

      //userData global
      //localStorage.setItem("userData", JSON.stringify(this.userData));
      store.commit("user/UPDATE_USER_DATA", this.userData);

      //paramData global
      //localStorage.setItem("paramData", JSON.stringify(this.paramData));
      store.commit("user/UPDATE_PARAM_DATA", this.paramData);

      //accessControl global
      //localStorage.setItem("accessControl", JSON.stringify(this.accessControl));
      store.commit("user/UPDATE_ACCESSCONTROL_DATA", this.accessControl);

      const ability = [
        {
          action: "manage",
          subject: "all",
        },
      ];

      this.$ability.update(ability);

      this.$router.replace(getHomeRouteForLoggedInUser("admin")).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title:
              this.$i18n.t("welcome") +
              ` ${
                this.employeeData.data.firstName ||
                this.employeeData.data.fullName
              }`,
            icon: "CoffeeIcon",
            variant: "success",
            text: this.$i18n.t("login_message"),
          },
        });
      });
    },
  },
  setup() {
    return {
      // Avatar
      avatarText,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.brand-text-pp {
  margin-bottom: -4rem;
  margin-left: -101px;
  margin-top: 19px;
  font-size: 10px;
  color: #d5d5d5;
}

.r-text {
  margin-left: 3px;
  margin-top: -1px;
  font-size: 20px;
}
</style>
