export default function sendToken(token) {
    let emailBody =
      "<html>" +
      '<body class="bg-light">' +
      '<div class="container">' +
      '<div class="card my-10">' +
      '<div class="card-body">' +
      '<h1 class="h3 mb-2">BRXMIND</h1>' +
      '<h5 class="text-teal-700">Código de acesso</h5>' +
      "<hr>" +
      '<div class="space-y-3">' +
      '<p class="text-gray-700">'+ token +' é seu código de acesso ao BRXMIND. Não o compartilhe.</p>' +
      "</div>" +
      "<br />" +
      "<hr>" +
      '<p class="text-gray-700 text-center" style="font-size: 11px; "> Este é um e-mail automático do BRXMIND, por favor não responder. <br />BRAXXY © Copyright 2022. Todos os direitos reservados. <br />' +
      '<a href="http://www.brxmind.com.br/PrivacyPolicy" style=" text-decoration: underline;font-size: 11px;line-height: 26.6px;">Política de privacidade</a>' +
      "</div>" +
      "</div>" +
      "</div>" +
      "</body>" +
      "</html>" +
      "<style>" +
      "      :root {" +
      '        --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";' +
      '        --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;' +
      "        --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));" +
      "        --bs-body-font-family: var(--bs-font-sans-serif);" +
      "        --bs-body-font-size: 1rem;" +
      "        --bs-body-font-weight: 400;" +
      "        --bs-body-line-height: 1.5;" +
      "        --bs-body-color: #212529;" +
      "        --bs-body-bg: #fff;" +
      "        --bs-border-width: 1px;" +
      "        --bs-border-style: solid;" +
      "        --bs-border-color: #dee2e6;" +
      "        --bs-border-color-translucent: rgba(0, 0, 0, 0.175);" +
      "        --bs-border-radius: 0.375rem;" +
      "        --bs-border-radius-sm: 0.25rem;" +
      "      }" +
      "      body {" +
      "        margin: 0;" +
      "        font-family: var(--bs-body-font-family);" +
      "        font-size: var(--bs-body-font-size);" +
      "        font-weight: var(--bs-body-font-weight);" +
      "        line-height: var(--bs-body-line-height);" +
      "        color: var(--bs-body-color);" +
      "        text-align: var(--bs-body-text-align);" +
      "        background-color: var(--bs-body-bg);" +
      "        -webkit-text-size-adjust: 100%;" +
      "        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" +
      "      }" +
      "      h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {" +
      "        margin-top: 0;" +
      "        margin-bottom: 0.5rem;" +
      "        font-weight: 500;" +
      "        line-height: 1.2;" +
      "      }" +
      "      h5, .h5 {" +
      "        font-size: 1.25rem;" +
      "      }" +
      "      .container," +
      "      .container-fluid," +
      "      .container-xxl," +
      "      .container-xl," +
      "      .container-lg," +
      "      .container-md," +
      "      .container-sm {" +
      "        --bs-gutter-x: 1.5rem;" +
      "        --bs-gutter-y: 0;" +
      "        width: 100%;" +
      "        padding-right: calc(var(--bs-gutter-x) * 0.5);" +
      "        padding-left: calc(var(--bs-gutter-x) * 0.5);" +
      "        margin-right: auto;" +
      "        margin-left: auto;" +
      "      }" +
      "      @media (min-width: 576px) {" +
      "        .container-sm, .container {" +
      "          max-width: 540px;" +
      "        }" +
      "      }" +
      "      @media (min-width: 768px) {" +
      "        .container-md, .container-sm, .container {" +
      "          max-width: 720px;" +
      "        }" +
      "      }" +
      "      @media (min-width: 992px) {" +
      "        .container-lg, .container-md, .container-sm, .container {" +
      "          max-width: 960px;" +
      "        }" +
      "      }" +
      "      @media (min-width: 1200px) {" +
      "        .container-xl, .container-lg, .container-md, .container-sm, .container {" +
      "          max-width: 1140px;" +
      "        }" +
      "      }" +
      "      @media (min-width: 1400px) {" +
      "        .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {" +
      "          max-width: 1320px;" +
      "        }" +
      "      }" +
      "      .btn {" +
      "        --bs-btn-padding-x: 0.75rem;" +
      "        --bs-btn-padding-y: 0.375rem;" +
      "        --bs-btn-font-family: ;" +
      "        --bs-btn-font-size: 1rem;" +
      "        --bs-btn-font-weight: 400;" +
      "        --bs-btn-line-height: 1.5;" +
      "        --bs-btn-color: #212529;" +
      "        --bs-btn-bg: transparent;" +
      "        --bs-btn-border-width: 1px;" +
      "        --bs-btn-border-color: transparent;" +
      "        --bs-btn-border-radius: 0.375rem;" +
      "        --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);" +
      "        --bs-btn-disabled-opacity: 0.65;" +
      "        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);" +
      "        display: inline-block;" +
      "        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);" +
      "        font-family: var(--bs-btn-font-family);" +
      "        font-size: var(--bs-btn-font-size);" +
      "        font-weight: var(--bs-btn-font-weight);" +
      "        line-height: var(--bs-btn-line-height);" +
      "        color: var(--bs-btn-color);" +
      "        text-align: center;" +
      "        text-decoration: none;" +
      "        vertical-align: middle;" +
      "        cursor: pointer;" +
      "        -webkit-user-select: none;" +
      "        -moz-user-select: none;" +
      "        user-select: none;" +
      "        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);" +
      "        border-radius: var(--bs-btn-border-radius);" +
      "        background-color: var(--bs-btn-bg);" +
      "        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;" +
      "      }" +
      "      @media (prefers-reduced-motion: reduce) {" +
      "        .btn {" +
      "          transition: none;" +
      "        }" +
      "      }" +
      "      .btn:hover {" +
      "        color: var(--bs-btn-hover-color);" +
      "        background-color: var(--bs-btn-hover-bg);" +
      "        border-color: var(--bs-btn-hover-border-color);" +
      "      }" +
      "      .btn-check:focus + .btn, .btn:focus {" +
      "        color: var(--bs-btn-hover-color);" +
      "        background-color: var(--bs-btn-hover-bg);" +
      "        border-color: var(--bs-btn-hover-border-color);" +
      "        outline: 0;" +
      "        box-shadow: var(--bs-btn-focus-box-shadow);" +
      "      }" +
      "      .btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {" +
      "        color: var(--bs-btn-active-color);" +
      "        background-color: var(--bs-btn-active-bg);" +
      "        border-color: var(--bs-btn-active-border-color);" +
      "      }" +
      "      .btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {" +
      "        box-shadow: var(--bs-btn-focus-box-shadow);" +
      "      }" +
      "      .btn-primary {" +
      "        --bs-btn-color: #fff;" +
      "        --bs-btn-bg: #0d6efd;" +
      "        --bs-btn-border-color: #0d6efd;" +
      "        --bs-btn-hover-color: #fff;" +
      "        --bs-btn-hover-bg: #0b5ed7;" +
      "        --bs-btn-hover-border-color: #0a58ca;" +
      "        --bs-btn-focus-shadow-rgb: 49, 132, 253;" +
      "        --bs-btn-active-color: #fff;" +
      "        --bs-btn-active-bg: #0a58ca;" +
      "        --bs-btn-active-border-color: #0a53be;" +
      "        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);" +
      "        --bs-btn-disabled-color: #fff;" +
      "        --bs-btn-disabled-bg: #0d6efd;" +
      "        --bs-btn-disabled-border-color: #0d6efd;" +
      "      }" +
      "      .card {" +
      "        --bs-card-spacer-y: 1rem;" +
      "        --bs-card-spacer-x: 1rem;" +
      "        --bs-card-title-spacer-y: 0.5rem;" +
      "        --bs-card-border-width: 1px;" +
      "        --bs-card-border-color: var(--bs-border-color-translucent);" +
      "        --bs-card-border-radius: 0.375rem;" +
      "        --bs-card-box-shadow: ;" +
      "        --bs-card-inner-border-radius: calc(0.375rem - 1px);" +
      "        --bs-card-cap-padding-y: 0.5rem;" +
      "        --bs-card-cap-padding-x: 1rem;" +
      "        --bs-card-cap-bg: rgba(0, 0, 0, 0.03);" +
      "        --bs-card-cap-color: ;" +
      "        --bs-card-height: ;" +
      "        --bs-card-color: ;" +
      "        --bs-card-bg: #fff;" +
      "        --bs-card-img-overlay-padding: 1rem;" +
      "        --bs-card-group-margin: 0.75rem;" +
      "        position: relative;" +
      "        display: flex;" +
      "        flex-direction: column;" +
      "        min-width: 0;" +
      "        height: var(--bs-card-height);" +
      "        word-wrap: break-word;" +
      "        background-color: var(--bs-card-bg);" +
      "        background-clip: border-box;" +
      "        border: var(--bs-card-border-width) solid var(--bs-card-border-color);" +
      "        border-radius: var(--bs-card-border-radius);" +
      "      }" +
      "      .card-body {" +
      "        flex: 1 1 auto;" +
      "        padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);" +
      "        color: var(--bs-card-color);" +
      "      }" +
      "      .bg-light {" +
      "        --bs-bg-opacity: 1;" +
      "        background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;" +
      "      }" +
      "    </style>";
  
    return emailBody;
  }
  